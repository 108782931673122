<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="480px"
      top="14%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="admin-detail-dialog"
      @close="closeEmail"
    >
      <div ref="inputBox" class="inputBox">
        <el-input
          v-for="(item, index) in emailData"
          :key="index"
          v-model="item.email"
          :ref="'input' + index"
          :placeholder="$t('dc:请输入想要邀请的同事邮箱')"
          style="width: 100%"
          @keyup.enter.native="addInput(item)"
          @blur="filter(item)"
        >
          <div slot="append">
            <el-button
              v-show="showStatusClose"
              @click="delInput(index)"
              icon="el-icon-close"
            ></el-button>
          </div>
        </el-input>
      </div>
      <span class="wornmsg">
        {{ wornMsg }}
      </span>
      <div slot="footer">
        <el-button type @click="show = false" class="marginR20">{{ $t('dc:否') }}</el-button>
        <el-button type="primary" @click="showOther">{{ $t('dc:是') }}</el-button>
      </div>
      <el-dialog width="30%" :title="$t('dc:确认')" :visible.sync="innerVisible" append-to-body>
        <div>
          {{ confrmMsg }}
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false" class="marginR20">{{ $t('dc:否') }}</el-button>
          <el-button type="primary" @click="sendEmail">{{ $t('dc:是') }}</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'adminDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    emailType: Number,
    rawData: Object,
    title: String,
    content: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    emailData(v) {
      if (this.emailData.length > 1) this.showStatusClose = true
      else this.showStatusClose = false
    },
  },
  data() {
    return {
      show: this.visible,
      titleMsg: this.title,
      contentMsg: this.content,
      innerVisible: false,
      showStatusClose: false,
      emailData: [
        {
          email: '',
        },
      ],
      wornMsg: this.$t('dc:注意每次最多同时邀请5位同事'),
      confrmMsg: this.$t('dc:您确认邀请吗?'),
    }
  },
  mounted() {},
  methods: {
    delInput(index) {
      if (this.emailData.length == 1) return
      this.emailData.splice(index, 1)
    },
    addInput(value) {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!mailReg.test(value.email)) {
        return this.$message({
          showClose: true,
          message: 'Please input valid format',
          type: 'warning',
        })
      }
      // if(this.emailData.length = 0) this.showStatusClose = false
      if (this.emailData.length > 4) return
      let addinput = { email: '' }
      this.emailData.push(addinput)
      this.$nextTick(() => {
        this.$refs[`input${this.emailData.length - 1}`][0].focus()
      })
    },
    showOther() {
      if (this.emailData[0].email == '')
        return this.$message({
          showClose: true,
          message: 'Please input valid format',
          type: 'warning',
        })
      this.innerVisible = true
    },
    filter(value) {},
    closeEmail() {
      this.$emit('closeEmail')
    },
    sendEmail() {
      if (this.emailData[0].email == '') return
      let params = {
        type: this.emailType,
        emailList: this.emailData,
        ...(this.rawData && { groupId: this.rawData.userGroupId }),
      }
      this.sendAllEmail(params)
    },
    //发送邮件
    async sendAllEmail(params) {
      let { status, data } = await $Api.group.getInvitationMail(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.innerVisible = false
        this.$emit('closeEmail')
      }
    },
    onChange() {},
  },
}
</script>
<style lang="scss">
.admin-detail-dialog .inputBox {
  padding: 2px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.admin-detail-dialog .el-dialog .el-dialog__body {
  text-align: left;
  .inputBox {
    .el-input__inner,
    .el-input-group__append,
    .el-input-group__prepend {
      border-color: transparent;
      background: transparent;
      // background:rgba(245,245,245,0.8);
    }
    // .el-input-group__append .el-button{
    //   background: transparent;
    // }
    // .el-input{
    //  font-size: 14px;
    // }
  }
}
.wornmsg {
  font-weight: 400;
  text-align: left;
  font-size: 12px;
}
</style>
